@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Only Add CSS Here that is global scope.
// Everything else should be in modules and Tailwind.

@layer utilities {
  /* Create a custom utility class */
  [data-selected='true'] {
    outline: 2px solid blue;
    border-radius: 8px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

@font-face {
  font-family: 'LH-Head-Bold';
  src: url('./fonts/LufthansaHead-Bold.otf');
}

@font-face {
  font-family: 'LH-Head-Light';
  src: url('./fonts/LufthansaHead-Light.otf');
}

@font-face {
  font-family: 'LH-Text-Thin';
  src: url('./fonts/LufthansaHead-Thin.otf');
}

@font-face {
  font-family: 'LH-Text-Regular';
  src: url('./fonts/LufthansaText-Regular.otf');
}

@font-face {
  font-family: 'LH-Text-Bold';
  src: url('./fonts/LufthansaText-Bold.otf');
}

body {
  margin: 0;
  font-family: 'LH-Text-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: rgb(244, 244, 244);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'LH-Head-Bold', sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}

.dark body {
  background-color: #000;
}

.dark .inbound-flights-grid::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 100px;
}

.dark .bagage-status-grid::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 100px;
}

.dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ul,
  li,
  ol,
  button,
  span,
  strong,
  em {
    color: #fff;
  }
}

//Dark Mode for Gantt
.dark [data-timelineitem] {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.dark [data-timelinetimesitem]::after {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.dark [data-currenttime] {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.dark [data-ganttlabel] {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark [data-isearly] {
  color: #fff !important;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@supports (scrollbar-width: auto) {
  * {
    @apply [scrollbar-color:#a3a3a3#0000] dark:[scrollbar-color:#f5f5f5#0000];
    scrollbar-width: thin;
  }
}

@supports selector(::-webkit-scrollbar) {
  ::-webkit-scrollbar-thumb {
    @apply bg-neutral-400 dark:bg-neutral-100;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}
