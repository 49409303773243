.flight-search-checkbox {
  input[type='checkbox'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 2px solid black;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #05164d;
    position: relative;
  }

  input[type='checkbox']:checked::after {
    content: url('../../Icon/icons/checked.svg');
    font-size: 12px;
    color: #05164d;
    position: absolute;
  }
}
