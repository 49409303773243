.bagage-status-grid {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 6px;

    border-top: 1px solid #e6e6e6;
  }

  &::-webkit-scrollbar-thumb {
    background: #05164d;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
  }
}
