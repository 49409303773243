#multi-select-dropdown .dropdown-container {
  border: none !important;
  outline: transparent !important;
  box-shadow: none !important;
  background: none !important;
  display: inline-block;
  width: 100%;
}

#multi-select-dropdown .dropdown-heading-value,
.dropdown-content,
.dropdown-heading-value > span.gray {
  color: #05164d;
  font-family: LH-Head-Bold;
  text-overflow: unset !important;
}

#multi-select-dropdown .dark {
  & .options {
    background-color: #202020;

    & .select-item:hover {
      background-color: #787878;
    }

    & .selected {
      background-color: #333333 !important;
    }
  }
}

#multi-select-dropdown .dark {
  .search,
  .search input:focus {
    background-color: #333333 !important;
    border-bottom: 0.5px solid #999999 !important;

    &:focus {
      color: #ffffff;
    }
  }
}

#multi-select-dropdown .dropdown-heading {
  display: flex;
}

#multi-select-dropdown .dropdown-heading-value {
  order: 2;
}

input[type='checkbox'][tabindex='-1'] {
  appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
}

#multi-select-dropdown .search-clear-button {
  outline: none;
}

#multi-select-dropdown ul.options > label:first-child {
  display: none !important;
}
