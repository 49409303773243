.drawer-container {
  --transition-speed: 0.3s;
}

.drawer {
  background: #fff;
  //   width: 30%;
  height: 100%;
  overflow: auto;
  position: fixed;
  transition: transform var(--transition-speed) ease;
  z-index: 100;
}
.drawer.right {
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.drawer-container.open .right {
  transform: translateX(0);
}
.backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.drawer-container .open .backdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 99;
}
