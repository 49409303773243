.scrollWrapper * {
  font-family: LH-Head-Text, sans-serif;
}
.scrollWrapper {
  position: relative;
  overflow: auto;
  // height: 330px;
  padding-bottom: 60px;
  overflow-y: hidden;
}

.currentTime {
  top: 0px;
  bottom: 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  z-index: 9;
}

.currentTime button {
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: -20px;
  margin-left: -32px;
  background-color: #05164d;
  color: #fff;
  width: 66px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 50px;
}

.timelineTimesWrapper {
  position: absolute;
  bottom: 0px;
  padding-bottom: 30px;
  left: -10px;
  width: 4100px;
}

.timelineTimesItem {
  position: relative;
  width: 135px;
  display: inline-block;
  font-size: 13px;
  text-align: center;
}

.timelineTimesItem::after {
  content: '';
  position: absolute;
  width: 1px;
  left: 50%;
  height: 1000px;
  margin-top: -1000px;
  top: 0px;
  bottom: 0px;
  background-color: #e6e6e6;
}

.timelineTimesItem strong {
  position: absolute;
  height: 8px;
  background-color: #e6e6e6;
  width: 1px;
  bottom: 21px;
  z-index: 2;
}

.timelineTimesItem strong:nth-child(1) {
  margin-left: 11px;
}
.timelineTimesItem strong:nth-child(2) {
  margin-left: 38px;
}
.timelineTimesItem strong:nth-child(3) {
  margin-left: 62px;
}
.timelineTimesItem strong:nth-child(4) {
  margin-left: 89px;
}

//Get Center
.onePixelCenterFinder {
  background-color: red;
  top: 200px;
  z-index: 999;
  position: absolute;
  width: 2px;
  height: 2px;
  pointer-events: none;
  left: 50%;
  margin-left: -1px;
  opacity: 0;
}

.processBar {
  position: relative;
  background-color: rgba(5, 22, 77, 0.1);
  height: 50px;
  margin-top: 30px;
  line-height: 50px;
  border-radius: 5px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
}

.dark .processBar {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.processBar span {
  position: fixed;
  left: 10px;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  height: 48px;
  margin-top: 1px;
  background-color: rgba(255, 255, 255, 0.8);
}

.processBar strong {
  float: right;
  padding-right: 16px;
}

.isAction {
  background-color: #52627c !important;
  position: absolute;
  height: 34px;
  left: 0px;
  right: 0px;
  z-index: -1;
  border-radius: 5px;
  margin-top: 8px;
}
.hasProcess span,
.hasProcess strong {
  color: #fff;
}
.hasDelay {
  color: #fff;
}
.delayBar {
  background-color: rgba(191, 64, 57, 0.8);
  position: absolute;
  height: 34px;
  line-height: 34px;
  color: #fff;
  left: 0px;
  right: 0px;
  z-index: 1;
  border-radius: 5px;
  margin-top: -42px;
}
.delayBar strong {
  margin-right: -60px;
  color: rgba(191, 64, 57, 1);
}
.delayBar span {
  z-index: 999;
  position: relative;
}

.hasProcess span,
.hasProcess strong {
  color: #fff;
}

.alertText {
  border: solid 1px rgba(191, 64, 57, 0.9);
  color: rgba(191, 64, 57, 1);
  padding: 12px 10px;
  border-radius: 5px;
  margin: 0px 15px 30px;
  display: block;
  clear: both;
}

.alertText h2 {
  font-size: 16px;
}

.alertText p {
  font-weight: 700;
  font-size: 13px;
  margin-top: -2px;
}

.alertText a {
  color: #05164d;
  text-decoration: underline;
}

.alertText::after {
  content: '';
  clear: both;
  display: table;
}

// Scroll Fix

.scrollFix {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

//Is early
[data-isearly] {
  color: #05164d;
  margin-right: -15px;
}
