#multi-select-dropdown-notifications .dropdown-heading-value,
.dropdown-content,
.dropdown-heading-value > span.gray {
  color: #05164d;
  font-family: LH-Text-Bold;
  text-overflow: unset !important;
}

#multi-select-dropdown-notifications .dropdown-container {
  border: 1px solid #05164d;
  background: none !important;
}

#multi-select-dropdown-notifications .dropdown-heading-value {
  overflow: hidden;
  width: 100px;
}

#multi-select-dropdown-notifications .dark {
  .dropdown-container {
    border: 1px solid #e6e6e6;
  }
  & .options {
    background-color: #202020;

    & .select-item:hover {
      background-color: #787878;
    }

    & .selected {
      background-color: #333333 !important;
    }
  }
}

#multi-select-dropdown-notifications .search-clear-button {
  display: none;
}

#multi-select-dropdown-notifications .clear-selected-button {
  display: none;
}

#multi-select-dropdown-notifications .dropdown-container[aria-disabled='true'] {
  opacity: 50%;
}
