@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.spinner-svg path {
  transform-origin: center;
  animation: spin 3s linear infinite;
}