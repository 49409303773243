.connection-flights-grid {
  display: grid;
  grid-template-columns: 130px 75px 95px 90px 65px 65px 80px 40px;
  row-gap: 24px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 6px;
    border-top: 1px solid #e6e6e6;
  }

  &::-webkit-scrollbar-thumb {
    background: #05164d;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
  }
}
