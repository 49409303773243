$pickerTopPosition: auto;
$pickerBottomPosition: 100%;

.rdtBtn {
  color: black !important;
}

.rdtPickerOpenUpwardsRight .rdtPicker {
  top: $pickerTopPosition;
  bottom: $pickerBottomPosition;
  left: 0;
}
.rdtPickerOpenUpwardsLeft .rdtPicker {
  top: $pickerTopPosition;
  bottom: $pickerBottomPosition;
  right: 0;
}

.rdtPicker {
  border-radius: 8px;
  border-color: #e5e7eb;
  box-shadow: none;

  span {
    color: black;
  }
}
